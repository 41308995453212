<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div class="save">
				<button
					class="btn_save"
					@click="postGrade"
				>완료</button>
			</div>
		</div>

		<div class="pt-50 ">
			<div class="pa-20">
				<div class="">
					<h6 class="h6 under-line pb-10">점수는 방문수(1점) + 게시글(3점)의 합산입니다.</h6>

					<div
						class="mt-20"
					>
						<label for="cartelName" >카르텔 개설자명</label>
						<div class="mt-10 bright_input" >
							<input
								v-model="items_grade[0].cartl_member_grade_name"
								type="text" id="cartelName" placeholder="카르텔 개설자명을 입력하세요" maxlength="20"
							>
						</div>
					</div>

					<!-- //카르텔이름 -->
					<!-- 카르텔설명 -->
					<div class="mt-20">
						<label for="cartelName" >카르텔 운영자명</label>
						<div class="mt-10 bright_input">
							<input
								v-model="items_grade[1].cartl_member_grade_name"
								type="text" id="cartelDesc" placeholder="카르텔 운영자명을 입력하세요" maxlength="40"
							>
						</div>
					</div>

					<div class="mt-20">
						<label for="cartelName" >가입 등급명</label>
						<div class="mt-10 bright_input">
							<input
								v-model="items_grade[2].cartl_member_grade_name"
								type="text" placeholder="가입 직후 등급을 입력하세요" maxlength="40"
							>
						</div>
					</div>
				</div>
			</div>

			<div
				v-for="(grade, g_index) in member_grade_list"
				:key="'grade_' + g_index"
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_mainbox mt-10">
						<div class="input_area" style="display: flex; justify-content: space-between">
							<div class="bright_input" style="flex: 2">
								<div class="input_utill">
									<input
										v-model="grade.cartl_member_grade_name"
										type="text" placeholder="등급명" maxlength="40"
									>
								</div>
							</div>
							<div
								style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: end; padding: 0"
							>
								<button
									v-if="grade.base_authority_fg != 'Y'"
									style="border: 1px solid #293097; color: #293097; border-radius: 10px; padding: 5px 10px; font-weight: 400; font-family: 'Open Sans';"
									@click="removeGrade(grade, g_index)"
								>삭제</button>
							</div>
						</div>
					</div>

					<div style="padding: 0 10px">
						<hr style="border-top: 1px solid #ddd">
					</div>

					<div class="subindex_mainbox ">

						<div class="input_area" style="display: flex; justify-content: space-between">

							<div
								style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: start; padding: 0"
							>
								<div
									style="font-size: 1.4rem; font-weight: 400; font-family: 'Open Sans';"
								>NFT 보유수량</div>
							</div>
							<div
								class="bright_input"
								style="flex: 1; padding: 0"
							>
								<div class="input_utill">
									<input
										v-model="grade.nft_holding_quantity"
										type="number" placeholder="0" maxlength="40"
										:rules="$rules.demical(grade, 'nft_holding_quantity', { min: 8, max: 8})"
										class="width-100"
										style="padding: 0 20px"
									>
								</div>
							</div>
						</div>
						<div class="input_area" style="display: flex; justify-content: space-between">

							<div
								style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: start; padding: 0"
							>
								<div
									style="font-size: 1.4rem; font-weight: 400; font-family: 'Open Sans';"
								>점수</div>
							</div>
							<div
								class="bright_input"
								style="flex: 1; padding: 0"
							>
								<div class="input_utill">
									<input
										v-model="grade.score"
										type="number" placeholder="0" maxlength="40"
										class="width-100"
										:rules="$rules.max(grade, 'score', 10)"
										style="padding: 0 20px"
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="subindex_item pa-20">
				<div class="btn_area mt-20">
					<button
						class="btn_l btn_fill_blue"
						@click="addGrade"
					><v-icon style="color: white; vertical-align: middle">mdi-plus</v-icon> 멤버 등급 추가</button>
				</div>
			</div>
		</div>

		<Popup_confirm
			v-if="is_on_delete"

			@click="postGradeDelete"
			@cancel="is_on_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.grade.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{ $language.grade.article_delete_message }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.grade.article_delete_message_confirm }}</template>
		</popup_confirm>
	</div>
</template>
<script>
import Popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafia0742'
	,
	components: {Popup_confirm},
	props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: '멤버 등급관리'
				, title: '멤버 등급관리'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
				}
			}
			, item_cartel: {
				creater_name: ''
				, operator_name: ''
				, entry_approval_fg: false
				, concern_sphere_code: []
				, user_id: this.user.id
				, items_grade: [
					{ name: ''}
				]
			}
			, cartel_img: ''
			, concern_sphere_code: []
			, step: 1
			, upload_file: ''
			, entry_approval_fg: false
			, entry_approval_chg_fg: false
			, entry_approval_chg_confirmation_message: ''
			, items_grade: [
				{ cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
				, { cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
				, { cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
			]
			, item_grade_info: {}
			, is_on_delete: false
			, item_grade_delete: {}
		}
	}
	, computed: {
		is_approval: function(){
			let t = true

			if(this.entry_approval_chg_confirmation_message.indexOf('가입대기중') > -1){
				t = false
			}
			return t
		}
		, member_grade_list: function(){
			let t = []

			this.items_grade.filter((item, index) => {
				if(index > 2){
					t.push(item)
				}
			})

			return t
		}
		, request_items_grade: function(){
			return this.items_grade.filter((item) => {
				item.nft_holding_quantity = Number(item.nft_holding_quantity)
				item.score = Number(item.score)

				return item
			})
		}
	}
	, methods: {
		getCartelGrade: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_open_cartel_member_grade_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_grade = result.data.cartl_member_grade_list
					this.item_grade_info = result.data.resultinfo
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e){

			this.cartel_img = this.$refs.file.files[0]
			console.log(e)

			const reader = new FileReader()
			let self = this
			reader.onload = function(e){
				self.upload_file = e.target.result
			}
			reader.readAsDataURL(e.target.files[0])
		}
		, postFile: async function(){
			try{
				this.$bus.$emit('on', true)
				if(this.cartel_img) {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700002'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: this.cartel_img
						}
						, multipart: true
						, type: true
					})

					if (result.success) {
						this.upload_file = result.data.file_url
						return true
					} else {
						throw result.message
					}
				}else{
					return true
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postGrade: async  function(){
			if(this.checkGrade()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_member_grade
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							// , cartl_member_div_code: 'CA02500003'
							, cartl_member_grade_save_list: this.request_items_grade
						}
						, type: true
					})

					if(result.success){
						await this.getCartelGrade()
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, checkGrade: function(){
			let t = false
			let d = {}
			try{
				this.items_grade.filter((item, index) => {
					if(index > 2){

						if(!item.cartl_member_grade_name){
							throw '등급명을 입력하세요'
						}else if(!(item.nft_holding_quantity + '')){
							throw 'NFT 보유수량을 입력하세요'
						}else if(!(item.score + '')){
							throw '점수를 입력하세요'
						}

						if(item.nft_holding_quantity > d.nft_holding_quantity || item.score > d.score) {
							t = true
						}else{
							throw '상위 등급은 하위등급보다 NFT 보유수량 또는 점수가 높아야 합니다.'
						}
					}else if(index > 1){

						if(!item.cartl_member_grade_name){
							throw '등급명을 입력하세요'
						}
					}else if(index > 0){
						if(!item.cartl_member_grade_name){
							throw '카르텔 운영자명을 입력하세요'
						}
					}else{
						if(!item.cartl_member_grade_name){
							throw '카르텔 개설자명을 입력하세요'
						}
					}

					d = item
					t = true
				})

				return t
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				t = false
				return t
			}
		}

		,goBack: function(){
			if(this.step == 1){
				this.$emit('goBack')
			}else{
				this.step = 1
			}
		}
		, addGrade: function(){
			this.items_grade.push({ cartl_member_grade_name: '', use_fg: 'Y', base_authority_fg: 'N', cartl_member_div_code: 'CA02500003'})
		}
		, removeGrade: async function(grade, index){
			let d_index = index + 3
			if(this.items_grade.length <= 1){
				this.$bus.$emit('notify', { type: 'error', message: '더 이상 삭제할 수 없습니다.'})
				return false
			}else{

				console.log('this.items_grade', this.items_grade)
				if(!grade.cartl_member_grade_number){
					this.items_grade.splice(d_index, 1)
				}else{
					this.is_on_delete = true
					this.item_grade_delete = grade
				}
				console.log('this.items_grade', d_index, this.items_grade)
			}
		}
		, postGradeDelete: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_member_grade_delete
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, cartl_member_div_code: this.item_grade_delete.cartl_member_div_code
						, cartl_member_grade_number: this.item_grade_delete.cartl_member_grade_number
						, use_fg: 'N'
					}
					, type: true
				})

				if(result.success){
					await this.getCartelGrade()
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getCartelGrade()
	}
}
</script>